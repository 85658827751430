@import 'standard';
@import 'small';
@import 'thin';
@import 'thin-rotating';

.bannerSize, .bannerSize--standard {
  @extend %bannerSize--standard;
}

.bannerWrapperSize--thin .bannerSize, .bannerSize--thin {
  @extend %bannerSize--thin;
}

.rotating-banner.bannerWrapperSize--thin .bannerSize, .rotating-banner.bannerWrapperSize--slim .bannerSize{
  @extend %rotating--bannerSize--thin;
}

.bannerWrapperSize--small .bannerSize, .bannerSize--small {
  @extend %bannerSize--small;
}