@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
@import '~react-modal-video/scss/modal-video.scss';

//@import './fonts';
@import './base';
@import './sections';
@import './form';

// top
//@import './layout/hamburger';
//@import './layout/top';
@import './layout/top-navbar';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';
@import './modules/multiColumnPanel';

// components
@import '../components';

@import './table/tableList';

@import './site/index';

//aos
@import "~aos/src/sass/aos";

@media print {
  @import './print';
}

//banner-text-bar
section.banner-text-bar{
  .htmlPanel{
    p{
      margin-bottom: 0px;
      text-align: center;
    }
  }
}

.dashboard__layout{
  margin-top: 4rem;
  padding-bottom: 8rem;
}

div.contentPanel.productViewPanel{
  padding-top: 2rem;
  padding-bottom: 2rem;
}

div.grecaptcha-badge{
  z-index: 101;
}

div.recapthca-container > div > div > div{
  margin: auto;
}

.signup.form-check-input{
  margin-top: 0.3rem;
  cursor: pointer;
  @include media-breakpoint-up(sm) { // 576px and up
    margin-top: 0.45rem;
  }
}

.logged-in-text{
  font-size: 1.5rem;
  @include media-breakpoint-up(sm) { // 576px and up
    font-size: 2rem;
  }
}

section.contentPanel#trade{
  transition: all 0.3s ease-in-out;
  &.hover{
    background-image: url('/sites/batteryspecialties/media/misc/trade-button-hover.png') !important;
  }
  @include media-breakpoint-down(xs){
    background-position: 44% 50%;
  }

  div.htmlPanel a{
    background-color: transparent;
  }
}

.search-result.productList {
  @include tile--titleOnly($breakpoints:(xs:2, sm:2, md:3, lg:3, xl:4, xxl:5), $ratio: 100%);
}