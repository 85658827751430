.tableList {
    width: 100%;
    //margin: 1.5rem 0;
    //margin-top: 0.5rem;
  
    th {
      background-color: #ddd;
      border-bottom: solid 1px #999;
      padding: 0.4rem;
    }
  
    td {
      padding: 0.2rem 0.4rem;
    }
  }
  
  .filter {
    .form-control {
      width: auto;
    }
}

.tableList tbody tr:nth-child(2n) {
    background-color: #f0f0f0;
}

.tableList tr td:nth-child(2){
    max-width: 50px;
}

.tableList tr td:nth-child(3){
    max-width: 50px;
}

.tableList tr td:nth-child(4){
    max-width: 350px;
}

.tableList tr td:nth-child(7){
    max-width: 70px;
}

// .tableList thead{
//     position: sticky;
//     top: 49px;
// }